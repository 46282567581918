import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import workflowApolloClient from '@/lib/appsync/workflow';
import { getConfigEnv, isGoodService, jsonParse } from '@/utils/helpers';
import getListEnabledWells from '@/graphql/workflow/queries/getListEnabledWells.graphql';
import getListEnabledPads from '@/graphql/workflow/queries/getListEnabledPads.graphql';
// import isOnline from 'is-online';
import userPreferenceDB from '@/lib/userPreference';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { mapDefaultProducingTasq } from '@/utils/producingTasqs';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'assetsModule',
  store,
})
class AssetsModule extends VuexModule {
  enabledWells: any = [];

  enabledPads: any = [];

  producingWells: TasqJob[] = []

  activeTasqID: any = ''

  activeTasq: TasqJob|null|undefined = null;

  @Mutation
  setEnabledWells(data): void {
    this.enabledWells = data;
  }

  @Mutation
  setEnabledPads(data): void {
    this.enabledPads = data;
  }

  @Mutation
  setProducingWells(data): void {
    this.producingWells = data;
  }

  @Mutation
  setActiveProducingTasq(wellName: any): void {
    this.activeTasq = this.producingWells.find((i) => i.wellName === wellName);
  }


  @Mutation
  setActiveTasqID(id: any): void {
    this.activeTasqID = id;
  }

  @Action
  async getEnabledPads(): Promise<void> {
    try {
      if (this.enabledPads && this.enabledPads.length) {
        return;
      }
      let items: any = [];
      const isOnlineApp = await isGoodService();

      if (isOnlineApp) {
        const {
          data: {
            list_enabled_pads: {
              enabled_pads: pads,
            },
          },
        } : any = await workflowApolloClient.query({
          query: getListEnabledPads,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          fetchPolicy: 'cache-first',
        });
        items = pads;

        items = items.map((i) => {
          const parsed = JSON.parse(i);
          return {
            wellName: parsed.NodeID,
            text: parsed.NodeID,
            value: parsed.NodeID,
            id: parsed.NodeID,
            engineerType: 'Producing',
            predictionType: 'Producing',
            assignee: {},
            route: parsed.Route,
            routeId: parsed.RouteID,
            level: parsed.Level,
            wells: parsed.Wells,
          };
        });

        items.sort((a, b) => a.wellName.localeCompare(b.wellName));

        // Remove duplicate wells
        const added_wells: any[] = [];
        const results: any[] = [];
        for (let b = 0; b < items.length; b++) {
          const containsWell = (added_wells.indexOf(items[b].wellName) > -1);
          if (!containsWell) {
            added_wells.push(items[b].wellName);
            results.push(items[b]);
          }
        }
        this.setEnabledPads(results);
        await userPreferenceDB.setItem('offline-enabledPads', results);
      } else {
        const results = await userPreferenceDB.getItem('offline-enabledPads');

        if (results) {
          this.setEnabledPads(results);
        }
      }
    } catch (error: any) {
      const results = await userPreferenceDB.getItem('offline-enabledPads');

      if (results) {
        this.setEnabledPads(results);
      } else {
        console.error(error.message);
      }
    }
  }

  @Action
  // eslint-disable-next-line consistent-return
  async getEnabledWells() {
    try {
      let items: any = [];
      if (this.enabledWells && this.enabledWells.length) {
        return;
      }
      const isOnline = await isGoodService();
      if (isOnline) {
        const {
          data: {
            list_enabled_wells: {
              enabled_wells: wells,
            },
          },
        } : any = await workflowApolloClient.query({
          query: getListEnabledWells,
          variables: {
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
          fetchPolicy: 'cache-first',

        });
        items = wells;
        if (tasqsListModule.enablePreloading) {
          await userPreferenceDB.setItem('getEnabledWells', wells);
        }
      } else {
        const result = await userPreferenceDB.getItem('getEnabledWells');
        if (result) {
          items = result;
        }
      }



      items = items.map((i) => {

        const parsed = JSON.parse(i);
        return {
          wellName: parsed.NodeID,
          text: parsed.NodeID,
          PadName: parsed.parent,
          value: parsed.NodeID,
          id: parsed.NodeID,
          engineerType: 'Producing',
          predictionType: 'Producing',
          assignee: {},
          route: parsed.Route,
          routeId: parsed.RouteID,
          level: parsed.Level,
          area: parsed.Area,

          wells: parsed.Wells,
        };
      });

      const producingWells = jsonParse(JSON.stringify(items)).map(
        (t: TasqJob) => mapDefaultProducingTasq(t),
      );

      this.setProducingWells(producingWells);
      await userPreferenceDB.setItem('setProducingWells:main', producingWells);

      items.sort((a, b) => a.wellName.localeCompare(b.wellName));

      // Remove duplicate wells
      const added_wells: any[] = [];
      const results: any[] = [];
      for (let b = 0; b < items.length; b++) {
        const containsWell = (added_wells.indexOf(items[b].wellName) > -1);
        if (!containsWell) {
          added_wells.push(items[b].wellName);
          results.push(items[b]);
        }
      }
      this.setEnabledWells(results);
      await userPreferenceDB.setItem('setEnabledWells:main', results);
    } catch (error: any) {
      const producingWells = await userPreferenceDB.getItem('setProducingWells:main');
      const enabledWells = await userPreferenceDB.getItem('setEnabledWells:main');
      if (enabledWells) {
        this.setEnabledWells(enabledWells);
      }

      if (producingWells) {
        this.setProducingWells(producingWells);
      } else {
        console.log(error.message);
      }
    }
  }
}

export default getModule(AssetsModule);
